import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { UnAuthorizedException } from '../../../../core/logic/exception';
import { SortListType } from '../../../../ui/dashboard/routes/questions/questions.service';
import { DietitianStateProvider } from '../../../dietitian/domain/dietitian_state_provider';
import { PatientGroup } from '../../../dietitian/domain/patient_group/patient_group';
import { Patient } from '../../../patient/domain/patient';
import { PatientRepository } from '../../../patient/repositories/patient_repository';
import { ConversationRepository } from '../../repositories/conversation_repository';
import { ConversationEventProvider } from '../events/conversation/conversation_event_provider';
import {
  ConversationCreated,
  ConversationRead,
  ConversationsCreated,
  ConversationUpdated,
} from '../events/conversation/conversation_events';
import { Conversation, ConversationProps } from './conversation';

@Injectable()
export class ConversationCommands {
  constructor(
    private repository: ConversationRepository,
    private patientRepository: PatientRepository,
    private eventProvider: ConversationEventProvider,
    private toastr: ToastrService,
    private dietitianStateProvider: DietitianStateProvider,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof ConversationCreated) {
        this.toastr.success('Conversation créée');
      }
      if (event instanceof ConversationsCreated) {
        this.toastr.success('Conversations créées');
      }
    });
  }

  async save(
    props: ConversationProps,
    conversationId?: string,
    notify = true,
    markAsRead = false,
  ) {
    let conversation = Conversation.create(
      props,
      new UniqueEntityID(conversationId),
    );
    if (conversationId !== undefined) {
      conversation = await this.repository.save(conversation);
      if (notify) {
        this.eventProvider.dispatch(new ConversationUpdated(conversation));
      }
    } else {
      conversation = await this.repository.create(conversation);
      if (notify) {
        this.eventProvider.dispatch(new ConversationCreated(conversation));
      }
    }
    if (notify && markAsRead) {
      this.eventProvider.dispatch(new ConversationRead(conversation));
    }

    return conversation;
  }

  async sendMessagesToGroupedPatients(
    subject: string,
    message: string,
    patientGroup: PatientGroup | undefined,
    archived: boolean,
  ): Promise<void> {
    await this.repository.sendMessagesToGroupedPatients(
      subject,
      message,
      patientGroup,
      archived,
    );
    this.eventProvider.dispatch(new ConversationsCreated());
    return Promise.resolve();
  }

  markDietitianConversationsAsRead() {
    return this.repository.markDietitianConversationsAsRead();
  }

  findConversation(ConversationId: string) {
    return this.repository.findConversation(ConversationId);
  }

  async findByDietitianIdCount(
    type?: SortListType,
    patientGroup?: PatientGroup,
  ): Promise<number> {
    if (this.dietitianStateProvider.state.entity !== undefined) {
      const count = this.repository.findByDietitianIdCount(
        this.dietitianStateProvider.state.entity.id.toString(),
      );

      // TODO: improve this by creating a profile prop inside patient
      let patients: Patient[];
      if (type == SortListType.Group && patientGroup?.patientGroupId) {
        patients = await this.patientRepository.getPatientGroupPatients(
          this.dietitianStateProvider.state.entity.dietitianId.id.toString(),
          patientGroup.patientGroupId.id.toString(),
        );
      } else if (type == SortListType.Archived) {
        const page = await this.patientRepository.searchPatients(
          this.dietitianStateProvider.state.entity.dietitianId.id.toString(),
          '',
          null,
          true,
          5000,
          0,
          undefined,
          false,
        );
        patients = page.results;
      } else {
        return count;
      }

      const conversations = await this.repository.findByDietitianId(
        this.dietitianStateProvider.state.entity.id.toString(),
      );

      return conversations.filter((conversation) => {
        let hasFound = false;

        for (const patient of patients) {
          if (
            conversation.patient?.profileId.id.toString() ===
            patient.patientId.id.toString()
          ) {
            hasFound = true;
          }
        }
        return hasFound;
      }).length;
    } else {
      return Promise.resolve(0);
    }
  }

  async findDietitianConversations(
    type?: SortListType,
    patientGroup?: PatientGroup,
  ): Promise<Conversation[]> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    const conversations = await this.repository.findByDietitianId(
      this.dietitianStateProvider.state.entity.id.toString(),
    );

    // TODO: improve this by creating a profile prop inside patient
    let patients: Patient[];
    if (type == SortListType.Group && patientGroup?.patientGroupId) {
      patients = await this.patientRepository.getPatientGroupPatients(
        this.dietitianStateProvider.state.entity.dietitianId.id.toString(),
        patientGroup.patientGroupId.id.toString(),
      );
    } else if (type == SortListType.Archived) {
      const page = await this.patientRepository.searchPatients(
        this.dietitianStateProvider.state.entity.dietitianId.id.toString(),
        '',
        null,
        true,
        5000,
        0,
        undefined,
        false,
      );
      patients = page.results;
    } else {
      return conversations;
    }

    return conversations.filter((conversation) => {
      let hasFound = false;

      for (const patient of patients) {
        if (
          conversation.patient?.profileId.id.toString() ===
          patient.patientId.id.toString()
        ) {
          hasFound = true;
        }
      }
      return hasFound;
    });
  }

  findDietitianConversationsForPatient(patientId: string) {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    return this.repository.findByDietitianIdAndPatientId(
      this.dietitianStateProvider.state.entity.id.toString(),
      patientId,
    );
  }

  async sendMessagesToPatientSelection(
    subject: string,
    message: string,
    patients: Patient[],
  ) {
    await this.repository.sendMessagesToPatientSelection(
      subject,
      message,
      patients,
    );
    this.eventProvider.dispatch(new ConversationsCreated());
    return Promise.resolve();
  }
}
